<template>
  <modal
    id="ModalNovoExplicaCodigoGerado"
    name="modalNameNovoExplicaCodigoGerado"
    :height="'auto'"
    :width="'40%'"
    :adaptive="true"
    :draggable="true"
    @opened="trazExplicacao"
  >
    <p class="p-4 text-left" v-html="explicacao"></p>
  </modal>
</template>

<script>
export default {
  data() {
    return {
      explicacao: null,
    };
  },
  props: ["type"],
  methods: {
    trazExplicacao() {
      switch (this.type) {
        case "usuario":
          this.explicacao = `
          <ul>
           <li class="li">
            O primeiro digito indica o tipo de usuário. <br>
           </li>
           <li class="li">
              Do segundo ao quarto digito é indicado o número do usuário dentro do sistema. <br>
           </li>
          </ul>
                    
          `;
          break;
        case "tema":
          this.explicacao = `
          <ul>
           <li class="li">
            Do primeiro dígito ao quarto digito é indicado o código do usuário que criou o tema; <br>
           </li>
           <li class="li">
             Do quinto digito ao sétimo, é indicado o código do tema dentro do sistema; <br>
           </li>
          </ul>
          `;
          break;

        case "exercicio":
          this.explicacao = `
            <ul>
            <li class="li">
              Iniciado com o <strong>código do Tema</strong> o qual o exercício pertencerá, seguido da <strong>ordem crescente</strong> de cadastro
            </li>
            </ul>   
            `;
          break;

        case "unidade":
          this.explicacao = `
            <ul>
           <li class="li">
            Sequencial numérico de acordo com a órdem de cadastramento da unidade no Banco de Dados.
           </li>
           </ul>`;
          break;

        default:
          break;
      }
    },
  },
};
</script>

<style scoped></style>
