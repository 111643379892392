var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{attrs:{"id":"ModalNovoEditarUsuario","name":"modalNameNovoEditarUsuario","height":'auto',"width":'40%',"adaptive":true,"draggable":true},on:{"opened":_vm.showDatas}},[_c('ModalExplicaCodigoGerado',{attrs:{"type":'usuario'}}),_c('div',{staticClass:"modalAdicionarTema container pt-3"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-10 mx-auto position-relative"},[_c('h3',{class:"h3-modal 0e5caf"},[_c('strong',{class:("" + (_vm.acao == 'adicionar' ? 'color-42cc7e' : 'color-fedd0a'))},[_vm._v(_vm._s(_vm.acao == "adicionar" ? "Novo" : "Editar"))]),_vm._v(" "+_vm._s(_vm.verifyStatus(_vm.objPerfil.typePerfil))+" ")]),_c('ValidationObserver',{ref:"form",staticClass:"py-3 text-left row",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.adicionarEditarUsuario.apply(null, arguments)}}},[(_vm.acao !== 'adicionar')?_c('div',{staticClass:"col-md-6 mb-4",on:{"click":function($event){return _vm.showModal('modalNameNovoExplicaCodigoGerado')}}},[_c('label',{attrs:{"for":""}},[_vm._v("Código do Usuário")]),_c('input',{staticStyle:{"height":"40px"},attrs:{"type":"text","disabled":""},domProps:{"value":_vm.dadosToEdit.cod}})]):_vm._e(),_c('ValidationProvider',{staticClass:"col-md-6",attrs:{"rules":"validadeCpf","name":"CPF","bails":false,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
            var ariaMsg = ref.ariaMsg;
            var classes = ref.classes;
return [_c('div',{staticClass:"control",class:classes},[_c('label',{attrs:{"for":""}},[_vm._v("CPF")]),_c('input',{directives:[{name:"mask",rawName:"v-mask",value:('###.###.###-##'),expression:"'###.###.###-##'"},{name:"model",rawName:"v-model",value:(_vm.objNovoUsuario.cpf),expression:"objNovoUsuario.cpf"}],staticClass:"form-control",class:classes,attrs:{"type":"text"},domProps:{"value":(_vm.objNovoUsuario.cpf)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.objNovoUsuario, "cpf", $event.target.value)}}}),_c('div',{staticClass:"text-left"},[_c('span',_vm._b({staticClass:"span-status-validate"},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])])])]}}])}),_c('ValidationProvider',{class:(" " + (_vm.acao !== 'adicionar' && (_vm.objPerfil.typePerfil == 3 || _vm.objPerfil.typePerfil == 4)
                ? 'col-md-12'
                : 'col-md-6')),attrs:{"rules":"required","name":"'Nome completo'","bails":false,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
            var ariaMsg = ref.ariaMsg;
            var classes = ref.classes;
return [_c('div',{staticClass:"control",class:classes},[_c('label',{attrs:{"for":""}},[_vm._v("Nome Completo")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.objNovoUsuario.name),expression:"objNovoUsuario.name"}],staticClass:"form-control",class:classes,attrs:{"type":"text"},domProps:{"value":(_vm.objNovoUsuario.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.objNovoUsuario, "name", $event.target.value)}}})]),_c('span',_vm._b({staticClass:"span-status-validate"},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}])}),(_vm.objPerfil.typePerfil == 3 || _vm.objPerfil.typePerfil == 4)?_c('ValidationProvider',{staticClass:"col-md-12 mb-4",attrs:{"rules":"excluded:0|required","name":"'unidade'"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
            var ariaMsg = ref.ariaMsg;
            var classes = ref.classes;
return [_c('div',{staticClass:"control mt-4",class:classes},[_c('label',{attrs:{"for":""}},[_vm._v("Unidade do "+_vm._s(_vm.verifyStatus(_vm.objPerfil.typePerfil))+" "+_vm._s(_vm.objNovoUsuario.name))]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.objNovoUsuario.unidade_id),expression:"objNovoUsuario.unidade_id"}],attrs:{"name":"","id":"instituição"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.objNovoUsuario, "unidade_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.getUnidades),function(item){return _c('option',{key:item.id,domProps:{"value":item.id}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0)]),_c('span',{staticClass:"span-status-validate"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,417730507)}):_vm._e(),_c('ValidationProvider',{staticClass:"col-md-6 mb-4",attrs:{"rules":"required","name":"'E-mail'","bails":false,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
            var ariaMsg = ref.ariaMsg;
            var classes = ref.classes;
return [_c('div',{staticClass:"control",class:classes},[_c('label',{attrs:{"for":""}},[_vm._v("E-mail")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.objNovoUsuario.email),expression:"objNovoUsuario.email"}],staticClass:"form-control",class:classes,attrs:{"type":"email"},domProps:{"value":(_vm.objNovoUsuario.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.objNovoUsuario, "email", $event.target.value)}}})]),_c('span',_vm._b({staticClass:"span-status-validate"},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}])}),_c('ValidationProvider',{staticClass:"col-md-6 senha",attrs:{"rules":"min:8","name":"'Senha'","bails":false,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
            var ariaMsg = ref.ariaMsg;
            var classes = ref.classes;
return [_c('div',{staticClass:"control",class:classes},[_c('label',{attrs:{"for":""}},[_vm._v("Senha")]),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"w-100 mr-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.objNovoUsuario.password),expression:"objNovoUsuario.password"}],staticClass:"form-control",class:classes,attrs:{"type":"text"},domProps:{"value":(_vm.objNovoUsuario.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.objNovoUsuario, "password", $event.target.value)}}})]),_c('div',{staticClass:"bg-42cc7e hoverStyleButton",staticStyle:{"border-radius":"5px"}},[_c('span',{staticClass:"d-flex j-c-center w-100 h-100",on:{"click":function($event){_vm.objNovoUsuario.password = _vm.geradorSenhas()}}},[_c('i',{staticClass:"fas fa-key color-fff px-2"})])])])]),_c('span',_vm._b({staticClass:"span-status-validate"},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}])}),_c('div',{staticClass:"btns d-flex align-items-center justify-content-center mt-4"},[_c('button',{staticClass:"btn-style-default color-fff bg-d42338",on:{"click":function($event){return _vm.fecharmodal()}}},[_vm._v(" cancelar ")]),_c('button',{staticClass:"btn-style-default color-fff bg-0e5caf ml-3",attrs:{"type":"submit","disabled":_vm.load}},[(!_vm.load)?_c('span',[_vm._v(" "+_vm._s(_vm.statusEdicao))]):_c('div',{staticClass:"spinner-border",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"})])])])],1)],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }