<template>
  <modal
    id="ModalNovoEditarUsuario"
    name="modalNameNovoEditarUsuario"
    :height="'auto'"
    :width="'40%'"
    :adaptive="true"
    :draggable="true"
    @opened="showDatas"
  >
    <ModalExplicaCodigoGerado :type="'usuario'" />
    <div class="modalAdicionarTema container pt-3">
      <div class="row">
        <div class="col-md-10 mx-auto position-relative">
          <h3 :class="`h3-modal 0e5caf`">
            <strong
              :class="`${
                acao == 'adicionar' ? 'color-42cc7e' : 'color-fedd0a'
              }`"
              >{{ acao == "adicionar" ? "Novo" : "Editar" }}</strong
            >
            {{ verifyStatus(objPerfil.typePerfil) }}
          </h3>

          <ValidationObserver
            ref="form"
            tag="form"
            class="py-3 text-left row"
            @submit.prevent="adicionarEditarUsuario"
          >
            <div
              v-if="acao !== 'adicionar'"
              class="col-md-6 mb-4"
              @click="showModal('modalNameNovoExplicaCodigoGerado')"
            >
              <label for="">Código do Usuário</label>
              <input
                style="height: 40px"
                type="text"
                disabled
                :value="
                  dadosToEdit.cod
                "
              />
            </div>
            <ValidationProvider
              v-slot="{ errors, ariaMsg, classes }"
              rules="validadeCpf"
              name="CPF"
              :bails="false"
              class="col-md-6"
              tag="div"
            >
              <div class="control" :class="classes">
                <label for="">CPF</label>
                <input
                  v-mask="'###.###.###-##'"
                  class="form-control"
                  :class="classes"
                  type="text"
                  v-model="objNovoUsuario.cpf"
                />
                <div class="text-left">
                  <span v-bind="ariaMsg" class="span-status-validate">{{
                    errors[0]
                  }}</span>
                </div>
              </div>
            </ValidationProvider>
            <ValidationProvider
              v-slot="{ errors, ariaMsg, classes }"
              rules="required"
              name="'Nome completo'"
              :bails="false"
              :class="` ${
                acao !== 'adicionar' && (objPerfil.typePerfil == 3 || objPerfil.typePerfil == 4)
                  ? 'col-md-12'
                  : 'col-md-6'
              }`"
              tag="div"
            >
              <div class="control" :class="classes">
                <label for="">Nome Completo</label>
                <input
                  type="text"
                  v-model="objNovoUsuario.name"
                  class="form-control"
                  :class="classes"
                />
              </div>
              <span v-bind="ariaMsg" class="span-status-validate">{{
                errors[0]
              }}</span>
            </ValidationProvider>
            <ValidationProvider
              v-if="objPerfil.typePerfil == 3 || objPerfil.typePerfil == 4"
              rules="excluded:0|required"
              name="'unidade'"
              class="col-md-12 mb-4"
              v-slot="{ errors, ariaMsg, classes }"
            >
              <div class="control mt-4" :class="classes">
                <label for=""
                  >Unidade do {{ verifyStatus(objPerfil.typePerfil) }}
                  {{ objNovoUsuario.name }}</label
                >
                <select
                  name=""
                  id="instituição"
                  v-model="objNovoUsuario.unidade_id"
                >
                  <option
                    v-for="item in getUnidades"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </option>
                </select>
                <!-- <multiselect
                  v-model="objNovoUsuario.unidade_id"
                  class="multiSelect text-capitalize"
                  track-by="name"
                  label="name"
                  :multiple="true"
                  :max-height="75"
                  placeholder="Selecione uma opção"
                  :options="unidadesId"
                  group-select="objNovoUsuario.unidade_id"
                  :allow-empty="false"
                  deselect-label="Remover"
                  selectedLabel="Selecionado"
                  selectLabel="Selecionar"
                >
                </multiselect>-->
              </div>
              <span class="span-status-validate">{{ errors[0] }}</span>
            </ValidationProvider>
            <ValidationProvider
              v-slot="{ errors, ariaMsg, classes }"
              rules="required"
              name="'E-mail'"
              :bails="false"
              class="col-md-6 mb-4"
              tag="div"
            >
              <div class="control" :class="classes">
                <label for="">E-mail</label>
                <input
                  type="email"
                  v-model="objNovoUsuario.email"
                  class="form-control"
                  :class="classes"
                />
              </div>
              <span v-bind="ariaMsg" class="span-status-validate">{{
                errors[0]
              }}</span>
            </ValidationProvider>
            <ValidationProvider
              v-slot="{ errors, ariaMsg, classes }"
              rules="min:8"
              name="'Senha'"
              :bails="false"
              class="col-md-6 senha"
              tag="div"
            >
              <div class="control" :class="classes">
                <label for="">Senha</label>
                <div class="d-flex">
                  <div class="w-100 mr-2">
                    <input
                      type="text"
                      v-model="objNovoUsuario.password"
                      class="form-control"
                      :class="classes"
                    />
                  </div>
                  <div
                    class="bg-42cc7e hoverStyleButton"
                    style="border-radius: 5px"
                  >
                    <span
                      @click="objNovoUsuario.password = geradorSenhas()"
                      class="d-flex j-c-center w-100 h-100"
                    >
                      <i class="fas fa-key color-fff px-2"></i>
                    </span>
                  </div>
                </div>
              </div>
              <span v-bind="ariaMsg" class="span-status-validate">{{
                errors[0]
              }}</span>
            </ValidationProvider>
            <!--   <div class="col-md-12">
              <h3 class="h3-modal mt-4  text-center">
                Dados Responsável
              </h3>
            </div>
            <ValidationProvider
              v-slot="{ errors, ariaMsg, classes }"
              rules="required"
              name="'nome'"
              :bails="false"
              class="col-md-6"
              tag="div"
            >
              <div class="control mt-3" :class="classes">
                <label for="">Nome Completo</label>
                <input
                  type="text"
                  v-model="objNovoUsuario.nome_responsavel"
                  class="form-control"
                  :class="classes"
                />
              </div>
              <span v-bind="ariaMsg" class="span-status-validate">{{
                errors[0]
              }}</span>
            </ValidationProvider>
            <ValidationProvider
              v-slot="{ errors, ariaMsg, classes }"
              rules="required"
              name="'telefone '"
              :bails="false"
              class="col-md-6"
              tag="div"
            >
              <div class="control mt-3" :class="classes">
                <label for="">Telefone</label>
                <input
                  type="text"
                  :mask="['(##) # ####-####']"
                  v-model="objNovoUsuario.telefone_responsavel"
                  class="form-control"
                  :class="classes"
                />
              </div>
              <span v-bind="ariaMsg" class="span-status-validate">{{
                errors[0]
              }}</span>
            </ValidationProvider>
            <ValidationProvider
              v-slot="{ errors, ariaMsg, classes }"
              rules="required"
              name="'Senha'"
              :bails="false"
              class="col-md-6 senha"
              tag="div"
            >
              <div class="control mt-3" :class="classes">
                <label for="">Senha</label>
                <div class="d-flex">
                  <div class="w-100 mr-2">
                    <input
                      type="text"
                      v-model="objNovoUsuario.password"
                      class="form-control"
                      :class="classes"
                    />
                  </div>
                  <div
                    class="bg-42cc7e hoverStyleButton"
                    style="border-radius: 5px"
                  >
                    <span
                      @click="objNovoUsuario.password = geradorSenhas()"
                      class="d-flex j-c-center w-100 h-100"
                    >
                      <i class="fas fa-key color-fff px-2"></i>
                    </span>
                  </div>
                </div>
              </div>
              <span v-bind="ariaMsg" class="span-status-validate">{{
                errors[0]
              }}</span>
            </ValidationProvider>-->
            <div
              class="btns d-flex align-items-center justify-content-center mt-4"
            >
              <button
                class="btn-style-default color-fff bg-d42338"
                @click="fecharmodal()"
              >
                cancelar
              </button>
              <button
                class="btn-style-default color-fff bg-0e5caf ml-3"
                type="submit"
                :disabled="load"
              >
                <span v-if="!load"> {{ statusEdicao }}</span>
                <div v-else class="spinner-border" role="status">
                  <span class="sr-only"></span>
                </div>
              </button>
              <!--<button class="btn-style-default color-fff bg-0e5caf ml-3" @click="chamaProximaModalLinkaTema()">próximo</button>-->
            </div>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import ModalExplicaCodigoGerado from "@/components/modais/ModalExplicaCodigoGerado";
import { mapGetters } from "vuex";
import Multiselect from "vue-multiselect";

export default {
  data() {
    return {
      selecting: "SELECIONAR",
      typePerfil: null,
      objNovoUsuario: {
        cod: "",
        name: "",
        cpf: "",
        email: "",
        nome_responsavel: "",
        telefone_responsavel: "",
        password: "",
        unidade_id: null,
        profile_id: this.objPerfil.typePerfil,
      },
      col12: "col-md-12",
      col6: "col-md-6",
      unidadesId: [],
      statusEdicao: "Salvar",
      load: false,
    };
  },
  components: {
    ModalExplicaCodigoGerado,
    Multiselect,
  },
  mounted() {
    this.listarUsuarios();
  },
  computed: {
    ...mapGetters(["getUnidades"]),
  },
  props: ["objPerfil", "dadosToEdit", "acao", "idTurma"],
  methods: {
    changeSelect(val) {
      this.selecting = val;
    },
    chamaProximaModalLinkaTema(val) {
      this.$emit("chamadaProximaModalLinkaTema", val);
    },
    codigoGerador() {
      this.objNovoUsuario.cod = this.geradorDeCodUsuario(
        this.objPerfil.typePerfil,
        this.objNovoUsuario.cpf
      );
    },
    async listarUsuarios() {
      const response = await fetch(
        `${this.VUE_APP_CMS}api/turma/getAlunos${this.unidadesId ? '/' + this.unidadesId : ''}`
      );
      console.log(response);
    },
    adicionarEditarUsuario() {
      //this.load = true;
      this.$refs.form.validate().then((success) => {
        this.objNovoUsuario.cod = this.geradorDeCodUsuario(
          this.objPerfil.typePerfil,
          this.objNovoUsuario.cpf
        );
        this.objNovoUsuario.profile_id = this.objPerfil.typePerfil;
        this.objNovoUsuario.cpf = this.objNovoUsuario.cpf
          ? this.objNovoUsuario.cpf.replace(/[^\d]+/g, "")
          : "";
        if (success) {
          this.$store.dispatch(
            "getDadosDeTodosUsuarios",
            this.$store.getters.getPerfilToken
          );
          this.show = true;
          this.statusEdicao = null;
          if (this.acao == "adicionar") delete this.objNovoUsuario.id;
          if (
            (this.acao == "editar" && this.objNovoUsuario.profile_id == 1) ||
            this.objNovoUsuario.profile_id == 2
          )
            delete this.objNovoUsuario.unidade_id;

          $.ajax({
            type: `${this.acao == "adicionar" ? "POST" : "PUT"}`,
            url: `${this.VUE_APP_CMS}api/users${
              this.acao == "editar" ? `/${this.objNovoUsuario.id}` : ""
            }`,
            data: this.objNovoUsuario,
            dataType: "json",
            headers: {
              Authorization: this.$store.getters.getPerfilToken,
              // "Content-Type": "application/x-www-form-urlencodd"
            },
            success: (response) => {
              this.show = false;
              this.$store.dispatch(
                "getDadosDeTodosUsuarios",
                this.$store.getters.getPerfilToken
              );
              this.$emit("atualizaDados");
              this.toastGlobal(
                "success",
                `O ${this.verifyStatus(this.perfilCadastrar)} '${
                  this.objNovoUsuario.name
                }' foi ${
                  this.acao == "adicionar" ? "Adicionado" : "Editado"
                } com sucesso`
              );

              this.statusEdicao = "Salvar";
              this.hideModal("modalNameNovoEditarUsuario");
              this.resetFormulario();
            },
            error: (response) => {
              this.load = false;
              this.show = false;
              this.statusEdicao = "Salvar";
              this.toastGlobal("error", `Erro: ${response.responseJSON.msg}`);
            },
          });
        } else {
          this.toastGlobal("error", `Preencha o formulário devidamente`);
        }
      });
    },
    fecharmodal() {
      this.hideModal("modalNameNovoEditarUsuario");
      this.resetFormulario();
    },

    resetFormulario() {
      this.selecting = "SELECIONAR";
      this.objNovoUsuario["id"] = null;
      this.objNovoUsuario.cod = this.codigoGerador();
      this.objNovoUsuario.name = null;
      this.objNovoUsuario.cpf = null;
      this.objNovoUsuario.email = null;
      this.objNovoUsuario.password = null;
      this.objNovoUsuario.nome_responsavel = null;
      this.objNovoUsuario.telefone_responsavel = null;
      this.objNovoUsuario.unidade_id = null;
      this.show = false;
    },
    showDatas() {
      if (this.dadosToEdit.name) {
        this.objNovoUsuario["id"] = this.dadosToEdit.id;
        this.objNovoUsuario.cod = this.dadosToEdit.cod;
        this.objNovoUsuario.name = this.dadosToEdit.name;
        this.objNovoUsuario.cpf = this.dadosToEdit.cpf;
        this.objNovoUsuario.email = this.dadosToEdit.email;
        this.objNovoUsuario.nome_responsavel =
          this.dadosToEdit.nome_responsavel;
        this.objNovoUsuario.telefone_responsavel =
          this.dadosToEdit.telefone_responsavel;
        this.objNovoUsuario.password = null;
        this.objNovoUsuario.unidade_id = this.dadosToEdit.unidade_id;
      } else {
        this.resetFormulario();
      }
      this.getUnidades.forEach((element) => {
        this.unidadesId.push(element.id);
      });
    },
  },
};
</script>

<style scoped>
.senha {
  margin: 0 auto;
}
</style>
