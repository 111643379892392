<template>
  <modal
    id="modalConfirmacao"
    name="modalNameConfirmacao"
    :height="'auto'"
    :width="'900px'"
    :adaptive="true"
    :draggable="true"
  >
    <div class="d-flex p-3 j-c-center">
      <div class="text-center">
        <h2 class="title">
          Deseja realmente {{ vontade }} {{ elementoVontade }} ?
        </h2>
      </div>
      <div>
        <Loader v-if="loader" />
        <button
          @click="exefuncaoVontade()"
          class="mx-3 color-fff btn-style-default bg-0e5caf"
        >
          Sim
        </button>
        <button
          @click="hideModal('modalNameConfirmacao')"
          class="color-fff btn-style-default bg-d42338"
        >
          Não
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
import Loader from "@/components/Loader";

export default {
  components: {
    Loader,
  },
  props: [
    "vontade",
    "elementoVontade",
    "funcaoVontade",
    "condicional",
    "dispararEvento",
    "valorParaDispatch",
  ],
  methods: {
    exefuncaoVontade() {
      switch (this.elementoVontade) {
        case "gravação":
          this.$emit("limparGravacao");
          break;
        case "exercício":
          this.$emit("concluirExercicio");
          break;
        case "deletado":
          this.$emit("deletarUsuario");
          break;
        case "deletada":
          this.$emit("deletarUnidade");
          break;
        case "turma seja deletada":
          this.$emit("deletarTurma");
          break;
        case "excluir tema":
          this.$emit("confirmaDeleteTema");
          break;
        default:
          break;
      }

      if (this.condicional) {
        this.$store.commit(`${this.funcaoVontade[0]}`, this.funcaoVontade[1]);
        let elementsToRemoveInDOM = document.getElementsByClassName("jogada");
        while (elementsToRemoveInDOM[0]) {
          elementsToRemoveInDOM[0].parentNode.removeChild(
            elementsToRemoveInDOM[0]
          );
        }
        this.hideModal("modalNameConfirmacao");

        this.toastGlobal("success", "Ação realizada com sucesso");
      } else {
        this.$store
          .dispatch(
            `${this.dispararEvento}`,
            this.valorParaDispatch ? this.valorParaDispatch : null
          )
          .then((resolve) => {
            if (resolve.valid) {
              this.loader = true;
              this.hideModal("modalNameConfirmacao");

              setTimeout(() => {
                this.toastGlobal("success", "Ação realizada com sucesso");

                if (this.elementoVontade == "excluir exercício") {
                  this.$emit("excluirExercicios");
                }
              }, 1250);
              this.reDispatch();
            } else {
              this.hideModal("modalNameConfirmacao");
              this.toastGlobal(
                "error",
                `Algo de errado ocorreu, ${resolve.msg.responseJSON.msg}`
              );
            }
          });
      }
    },
    reDispatch() {
      this.$store.dispatch(
        `${this.dispararEvento}`,
        this.valorParaDispatch ? this.valorParaDispatch : null
      );
    },
  },
};
</script>

<style></style>
